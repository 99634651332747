import { TCreateSector } from "../../models";
import { axiosHttpClient } from "../axios";
import { findErrorString } from "../find-error-string";
import handleStatusCases from "../handle-status-cases";

const { REACT_APP_BASE_URL } = process.env;

export default async ({
    projectsId,
    name,
    description,
}: TCreateSector = {}) => {
    const params = {
        name,
        description,
    };

    const tempHeaders = {
        auth: true,
        "x-projects-id": projectsId,
    };

    if (!projectsId) delete tempHeaders["x-projects-id"];

    const httpResponse = await axiosHttpClient({
        url: `${REACT_APP_BASE_URL}/sector/create`,
        method: "post",
        headers: tempHeaders,
        body: params,
    });
    const { body, statusCode } = httpResponse;

    const errors = {
        "sector already exists!": "Já existe um setor com o mesmo nome!",
        "A sector with this name already exists!":
            "Já existe um setor com o mesmo nome!",
    };

    const errorMessage = findErrorString<keyof typeof errors>(body);

    const errorFeedBacks = {
        404: "Sem setores!",
        409: errors[errorMessage] || "Houve um problema ao criar o setor!",
    };

    return handleStatusCases({
        body,
        statusCode,
        errorFeedBacks,
    });
};
