import { useEffect } from "react";
import {
    Routes,
    Route,
    Navigate,
    useNavigate,
    useLocation,
    Outlet,
} from "react-router-dom";

import { useUser } from "../context";
import {
    Register,
    RegisterAdmin,
    Collaborator,
    Collaborators,
    CollaboratorsInvite,
    Home,
    Login,
    PasswordExpired,
    PasswordRecover,
    PasswordReset,
    ReportDetailed,
    ReportGenerated,
    ReportSummary,
    Track,
    Roles,
    OrganizationReport,
    OrganizationProfile,
    OrganizationProjects,
    Sectors,
    Invite,
    SectorsForm,
    RolesForm,
    ReportManage,
    ReportManageCollaborators,
    ReportManageInvoicing,
    ConfigProfile,
    ProjectsForm,
    Project,
    NotFoundPage,
} from "../pages";
import { BackOffice } from "../pages/backoffice/backoffice";
import { GoogleRegister } from "../pages/google-register/google-register";
import { MaintenancePage } from "../pages/information-pages/maintenance-page/maintenance-page";
import { UnavailablePage } from "../pages/information-pages/unavailable-page/unavailable-page";
import { PlanSubscription } from "../pages/manage-subscription/sub-pages/subscription/plan-subscription";
import { NoAccess } from "../pages/no-access/no-access";
import { Clients } from "../pages/organization/clients/clients";
import { Organization } from "../pages/organization/organization";
import { ScorePage } from "../pages/score/score";
import { ChangeCreditCard } from "../pages/subscriptions/pages/change-credit-card/change-credit-card";
import { Subscriptions } from "../pages/subscriptions/subscriptions";
import { SuccessInactivationPage } from "../pages/success-inactivation/success-inactivation";
import { Auth } from "../services/auth/Authentication";
import { redirectDefaultRoute } from "../utils";
import { PrivateRoute } from "./private-routes";

const AppRoutes = () => {
    const { isLogged, haveOrganization, user, verifyPermission, permissions } =
        useUser();
    const manageProjectsSectorsPermission = verifyPermission({
        id: "projects_sectors",
        rule: "manage",
    });
    const manageRolesPermission = verifyPermission({
        id: "roles_permissions",
        rule: "manage",
    });
    const viewSectorsPermission = verifyPermission({
        id: "projects_sectors",
        rule: "view",
    });
    const viewRolesPermission = verifyPermission({
        id: "roles_permissions",
        rule: "view",
    });
    const manageOrganization = verifyPermission({
        id: "manage_teams_projects",
        rule: "enabled",
    });
    const manageProfessionalsPermission = verifyPermission({
        id: "professionals",
        rule: "manage",
    });
    const viewUsers = verifyPermission({
        id: "professionals",
        rule: "view",
    });
    const clientsReportPermission =
        manageProjectsSectorsPermission ||
        manageRolesPermission ||
        viewSectorsPermission ||
        manageOrganization;
    const accessInvite = manageProfessionalsPermission && manageRolesPermission;
    const isAdmin = user.Role.name === "Administrador";
    const isBackOfficeAccount = user.email === "backoffice@paperon.app";

    const navigate = useNavigate();
    const { state } = useLocation();

    const { unauthenticatedRoutes } = Auth.getInstance();

    useEffect(() => {
        if (state) return;
        if (
            unauthenticatedRoutes.includes(
                window.location.pathname.replaceAll("/", "")
            )
        )
            return;
        setTimeout(() => {
            const timestamp = new Date().getTime();
            navigate(`${window.location.pathname}?t=${timestamp}`);
        }, 500);
    }, [state, window.location.pathname]);

    useEffect(() => {
        setTimeout(() => {
            if (window.location.pathname === "/organizacao") {
                navigate(`/organizacao${redirectDefaultRoute(permissions)}`);
            }
        }, 1000);
    }, [window.location.pathname, permissions, navigate]);

    return (
        <Routes>
            <Route element={<Outlet />}>
                <Route path="/" element={<Home />} />
                <Route path="/login" element={<Login />} />
                <Route path="/link-expirado" element={<PasswordExpired />} />
                <Route path="/recuperar-senha" element={<PasswordRecover />} />
                <Route path="/cadastro" element={<Register />} />
                <Route path="/sem-acesso" element={<NoAccess />} />
                <Route
                    path="/sucesso-inativacao"
                    element={<SuccessInactivationPage />}
                />
                <Route
                    path="/convite-admin/:inviteToken"
                    element={<RegisterAdmin />}
                />
                <Route path="/convite/:inviteToken" element={<Invite />} />
                <Route
                    path="/nova-senha/:emailToken"
                    element={<PasswordReset />}
                />
                <Route path="/registro-google" element={<GoogleRegister />} />
                <Route path="/not-found" element={<NotFoundPage />} />
                <Route path="/manutencao" element={<MaintenancePage />} />
                <Route path="/indisponivel" element={<UnavailablePage />} />
            </Route>

            <Route element={<PrivateRoute isLogged={isLogged} />}>
                {!haveOrganization ? (
                    <Route path="/registrar-empresa" element={<Register />} />
                ) : (
                    <>
                        {isBackOfficeAccount && (
                            <Route
                                path="/backoffice"
                                element={<BackOffice />}
                            />
                        )}
                        <Route
                            path="/meus-relatorios/detalhado"
                            element={<ReportDetailed />}
                        />
                        <Route
                            path="/meus-relatorios/resumo"
                            element={<ReportSummary />}
                        />
                        <Route
                            path="/meus-relatorios/gerados"
                            element={<ReportGenerated />}
                        />
                        <Route
                            path="/relatorio/gerenciar"
                            element={<ReportManage />}
                        />
                        <Route
                            path="/relatorio/gerenciar/revisar"
                            element={<ReportManage />}
                        />
                        <Route
                            path="/relatorio/gerenciar/faturar"
                            element={<ReportManageInvoicing />}
                        />
                        <Route
                            path="/relatorio/gerenciar/revisar/:userName"
                            element={<ReportManageCollaborators />}
                        />
                        <Route path="/cronometro" element={<Track />} />
                        <Route path="/pontuacao" element={<ScorePage />} />
                        <Route
                            path="/profissional/:userName"
                            element={<Collaborator />}
                        />
                        <Route path="/projetos/:name" element={<Project />} />
                        {isAdmin && (
                            <>
                                <Route
                                    path="/gerenciar-assinatura"
                                    element={<Subscriptions />}
                                />
                                <Route
                                    path="/gerenciar-assinatura/editar-forma-de-pagamento"
                                    element={<ChangeCreditCard />}
                                />
                                <Route
                                    path="/gerenciar-assinatura/assinatura-do-plano"
                                    element={<PlanSubscription />}
                                />
                            </>
                        )}
                        {viewUsers && (
                            <>
                                <Route
                                    path="/profissionais"
                                    element={<Collaborators />}
                                />
                                {accessInvite &&
                                    manageProfessionalsPermission && (
                                        <Route
                                            path="/profissionais/convites"
                                            element={<CollaboratorsInvite />}
                                        />
                                    )}
                            </>
                        )}
                        <Route
                            path="/perfil/configuracoes"
                            element={<ConfigProfile />}
                        />
                        {manageOrganization && (
                            <>
                                <Route
                                    path={`/organizacao/perfil`}
                                    element={<OrganizationProfile />}
                                />
                                <Route
                                    path={"/assinaturas/plano"}
                                    element={<PlanSubscription />}
                                />
                            </>
                        )}
                        <Route
                            path="/projetos"
                            element={<OrganizationProjects />}
                        />
                        {manageProjectsSectorsPermission && (
                            <Route
                                path="/criar-projetos"
                                element={<ProjectsForm />}
                            />
                        )}
                        <Route path={`/organizacao`} element={<Organization />}>
                            {viewSectorsPermission && (
                                <>
                                    <Route
                                        path="setores"
                                        element={<Sectors />}
                                    />
                                    <Route
                                        path="setores/visualizar"
                                        element={<SectorsForm view />}
                                    />
                                </>
                            )}
                            {manageProjectsSectorsPermission && (
                                <>
                                    <Route
                                        path="setores/criar"
                                        element={<SectorsForm />}
                                    />
                                    <Route
                                        path="clientes"
                                        element={<Clients />}
                                    />
                                    <Route
                                        path="setores/editar"
                                        element={<SectorsForm edit />}
                                    />
                                </>
                            )}
                            {viewRolesPermission && (
                                <>
                                    <Route
                                        path="atuacoes"
                                        element={<Roles />}
                                    />
                                    <Route
                                        path="atuacoes/visualizar"
                                        element={<RolesForm view />}
                                    />
                                    {manageRolesPermission && (
                                        <>
                                            <Route
                                                path="atuacoes/criar"
                                                element={<RolesForm />}
                                            />
                                            <Route
                                                path="atuacoes/editar"
                                                element={<RolesForm edit />}
                                            />
                                        </>
                                    )}
                                </>
                            )}
                            <Route
                                path="projetos/:name"
                                element={<Project />}
                            />
                            {manageProjectsSectorsPermission && (
                                <>
                                    <Route
                                        path="projetos"
                                        element={<OrganizationProjects />}
                                    />
                                </>
                            )}
                            {clientsReportPermission && (
                                <Route
                                    path="/organizacao/demonstrativos"
                                    element={<OrganizationReport />}
                                />
                            )}
                        </Route>
                    </>
                )}

                <Route
                    path="*"
                    element={
                        <Navigate
                            to={
                                !haveOrganization
                                    ? "/registrar-empresa"
                                    : "/not-found"
                            }
                            replace
                        />
                    }
                />
            </Route>
        </Routes>
    );
};

export default AppRoutes;
