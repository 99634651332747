import { useQuery } from "@tanstack/react-query";

import { readManagers } from "../../services/user";

type Response = Array<{
    id_e: string;
    name: string;
}>;

export function useReadManagers() {
    return useQuery<Response>({
        queryKey: ["readManagers"],
        queryFn: () => readManagers(),
    });
}
