import { cloneDeep } from "lodash";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";

import { FotoRapaz, OfficeInteraction, OfficeSpace } from "../../assets";
import { LoadingGray, PrimaryButton } from "../../components";
import { useUser } from "../../context";
import { useFetch } from "../../hooks";
import {
    TAuthBody,
    TAuthError,
    TAuthResponse,
    TCreateUserResponse,
    TGetUserInvite,
} from "../../models";
import { auth, manageUser, invite, projects } from "../../services";
import { removePhoneMask } from "../../utils";
import { RegisterFinalStep } from "../cadastro/components/register-steps/final-step/final-step";
import * as s from "./styled-invite";

export type TUserData = TGetUserInvite | TCreateUserResponse;

type TData = {
    password_confirmation: string | undefined;
    celNumber: string | undefined;
    name: string;
    organizationName?: string;
    phone?: string;
    password: string;
    confirmPassword?: string;
};

export const Invite = () => {
    const { inviteToken } = useParams<{ inviteToken?: string }>();

    const [userData, setUserData] = useState<TUserData>();
    const [loginData, setLoginData] = useState<TAuthBody>();
    const [tokenStatus, setTokenStatus] = useState<string>();
    const {
        setIsLogged,
        setPermissions,
        setUserProjects,
        setWorkedHours,
        setHaveOrganization,
    } = useUser();

    const navigate = useNavigate();
    const { createUser } = manageUser;

    const loginReq = useFetch<TAuthBody, TAuthResponse, TAuthError>({
        fn: auth,
        start: false,
    });

    const getUser = useFetch({
        fn: manageUser.getUser,
        start: false,
    });

    const getProjects = useFetch({ fn: projects.getProject, start: false });

    const postCreateUser = useFetch({
        fn: createUser,
        start: false,
        noError: true,
    });

    const getInviteData = useFetch({
        fn: invite.getInviteUser,
        start: false,
    });

    const returnImage = () => {
        if (tokenStatus === "invalid") return OfficeSpace;
        if (tokenStatus === "redeemed") return OfficeInteraction;
        return FotoRapaz;
    };

    const postUserData = (data: TData) => {
        const formData = cloneDeep({ ...data });

        const body = {
            ...formData,
            ...userData,
            birthDate: null,
            admissionDate: new Date(userData?.admissionDate as string),
            inviteToken,
            terms: true,
        };
        delete body.organizationName;

        postCreateUser.onRefresh(body);
        setLoginData({
            email: userData?.email as string,
            password: data.password,
        });
    };

    const redirect = (haveOrganization?: boolean) => {
        if (!haveOrganization) return navigate("/registrar-empresa");
        return navigate("/cronometro");
    };

    useEffect(() => {
        if (postCreateUser.response) {
            loginReq.onRefresh(loginData);
        }
    }, [postCreateUser.response]);

    useEffect(() => {
        if (postCreateUser.error) {
            toast.error("Telefone já cadastrado! Tente outro.");
        }
    }, [postCreateUser.error]);

    useEffect(() => {
        if (inviteToken) {
            getInviteData.onRefresh(inviteToken);
        }
    }, []);

    useEffect(() => {
        if (getInviteData.response) {
            const tempUserData = getInviteData.response;
            delete tempUserData.massInvite;

            if (tempUserData.celNumber) {
                tempUserData.celNumber = removePhoneMask(
                    tempUserData.celNumber
                );
            }

            setUserData(tempUserData);

            setTokenStatus("ok");
        }
        if (getInviteData.error) {
            const errorMessage = getInviteData.error?.body?.message;

            switch (errorMessage) {
                case "Invalid Token":
                    setTokenStatus("invalid");
                    break;
                case "This invite has already been redeemed":
                    setTokenStatus("redeemed");
                    break;
                case "Invitation does not exists!":
                    setTokenStatus("not-exist");
                    break;
                default:
                    setTokenStatus("ok");
            }
        }
    }, [getInviteData.response || getInviteData.error]);

    useEffect(() => {
        if (loginReq.response) {
            setIsLogged(true);
            setPermissions(loginReq.response.userPermsId);
            toast.success("Logado com sucesso");
            localStorage.setItem(
                "haveOrganization",
                JSON.stringify(!!loginReq.response.user.organization)
            );
            setHaveOrganization(!!loginReq.response.user.organization);

            getUser.onRefresh();
        }
    }, [loginReq.response]);

    useEffect(() => {
        if (getUser.response && loginReq.response) {
            setWorkedHours({
                total: getUser.response.monthlyTime,
                limit:
                    (getUser?.response?.Organization?.monthly_hour_limit ?? 1) *
                    60,
            });

            if (loginReq.response.user.organization) {
                getProjects.onRefresh({ sectors: true });
            } else {
                redirect(Boolean(loginReq.response.user.organization));
            }
        }
    }, [getUser.response]);

    useEffect(() => {
        if (getProjects.response && loginReq.response) {
            setUserProjects(getProjects.response.projects);

            redirect(Boolean(loginReq.response.user.organization));
        }
    }, [getProjects.response]);

    return (
        <s.Container $loading={getInviteData.loading || !tokenStatus}>
            {getInviteData.loading || !tokenStatus ? (
                <LoadingGray />
            ) : (
                <>
                    <s.Img
                        src={returnImage()}
                        style={
                            tokenStatus !== "ok" ? { maxHeight: "100vh" } : {}
                        }
                    />

                    {userData ? (
                        <s.FlexColumn>
                            <RegisterFinalStep
                                userData={userData}
                                postUserData={postUserData}
                            />
                        </s.FlexColumn>
                    ) : (
                        <s.Section invert={tokenStatus === "invalid"} center>
                            {tokenStatus === "redeemed" ? (
                                <div id="invite-concluded">
                                    <h1 style={{ marginBottom: "1rem" }}>
                                        Convite já preenchido
                                    </h1>

                                    <p>
                                        Utilize seu e-mail corporativo e a senha
                                        que você cadastrou e faça seu login no
                                        PaperOn.
                                    </p>

                                    <PrimaryButton
                                        onClick={() => navigate("/login")}
                                        className="redirect"
                                    >
                                        FAZER LOGIN
                                    </PrimaryButton>
                                </div>
                            ) : (
                                <>
                                    {tokenStatus === "invalid" ? (
                                        <>
                                            <h1
                                                style={{ marginBottom: "1rem" }}
                                            >
                                                Convite expirado
                                            </h1>

                                            <p>
                                                Seu convite para começar no
                                                PaperOn tem validade de 24 horas
                                                após o envio. Como este prazo já
                                                passou, ele foi expirado.
                                            </p>
                                        </>
                                    ) : (
                                        <>
                                            <h1
                                                style={{ marginBottom: "1rem" }}
                                            >
                                                Convite inexistente
                                            </h1>

                                            <p>
                                                Este convite não consta em nosso
                                                banco de dados.
                                            </p>
                                        </>
                                    )}

                                    <span id="line" />

                                    <span id="question">Como resolver?</span>

                                    <p>
                                        {tokenStatus === "redemeed"
                                            ? "Entre em contato com seu gestor e peça para que ele te reenvie."
                                            : "Entre em contato com seu gestor e peça para que ele envie o convite."}
                                    </p>
                                </>
                            )}
                        </s.Section>
                    )}
                </>
            )}
        </s.Container>
    );
};
