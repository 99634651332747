import { yupResolver } from "@hookform/resolvers/yup";
import { CredentialResponse, GoogleLogin } from "@react-oauth/google";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { Link, useLocation, useNavigate } from "react-router-dom";

import { FotoMoca } from "../../assets";
import { Input, PrimaryButton } from "../../components";
import { TProject, useUser } from "../../context";
import { useFetch } from "../../hooks";
import { TAuthBody, TAuthError, TAuthResponse } from "../../models";
import { auth, manageUser, projects, OAuth } from "../../services";
import { OAuthParams } from "../../services/auth/OAuth";
import { TUseFetch } from "../../types/TUseFetch";
import { Mixpanel } from "../../utils/mixpanel/Mixpanel";
import generateForm, { TFormInputs } from "./generate-form";
import { InactiveAdminModal } from "./inactive-admin-modal/inactive-admin-modal";
import * as s from "./styled-login";
import schema from "./validation";

export const Login = () => {
    const {
        setIsLogged,
        setPermissions,
        setUserProjects,
        setWorkedHours,
        setHaveOrganization,
    } = useUser();
    const navigate = useNavigate();

    const {
        register,
        trigger,
        setError,
        handleSubmit,
        formState: { errors },
    } = useForm<TFormInputs>({ resolver: yupResolver(schema()) });

    const [openInactiveAdminModal, setOpenInactiveAdminModal] = useState(false);
    const [loginData, setLoginData] = useState<TAuthBody>();
    const [googleLoginData, setGoogleLoginData] = useState<string>();

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const reason = queryParams.get("reason");
    const credential = queryParams.get("credential");
    const howYouKnowUs = queryParams.get("howYouKnowUs");

    const loginReq = useFetch<TAuthBody, TAuthResponse, TAuthError>({
        fn: auth,
        start: false,
    });

    const getUser = useFetch({
        fn: manageUser.getUser,
        start: false,
    });

    const authenticateWithGoogle = useFetch<
        OAuthParams,
        TAuthResponse,
        TAuthError
    >({
        fn: OAuth,
        start: false,
    });

    const getProjects = useFetch({ fn: projects.getProject, start: false });

    const userMustFinishRegister =
        authenticateWithGoogle?.response?.code === "USER_MUST_FINISH_REGISTER";

    const onSubmit = (data: TAuthBody) => {
        loginReq.onRefresh(data);
        setLoginData(data);
    };

    const redirect = (haveOrganization?: boolean) => {
        if (!haveOrganization) return navigate("/registrar-empresa");
        return navigate("/cronometro");
    };

    const login = (response: TAuthResponse) => {
        setIsLogged(true);
        setPermissions(response.userPermsId);
        toast.success("Logado com sucesso");
        localStorage.setItem(
            "haveOrganization",
            JSON.stringify(!!response.user?.organization)
        );
        setHaveOrganization(!!response.user?.organization);

        getUser.onRefresh();

        redirect(!!response.user?.organization);
    };

    const setProjects = (
        projects: TProject[],
        user: { name: string; email: string; organization?: string | undefined }
    ) => {
        setUserProjects(projects);
        redirect(!!user?.organization);
    };

    const handleUserOrganizationData = (
        getUser: TUseFetch,
        loginReq: TUseFetch
    ) => {
        setWorkedHours({
            total: getUser.response.monthlyTime,
            limit:
                (getUser?.response?.Organization?.monthly_hour_limit || 1) * 60,
        });

        if (loginReq.response.user?.organization) {
            getProjects.onRefresh({ sectors: true });
        } else {
            redirect(!!loginReq.response.user?.organization);
        }
    };

    const handleGoogleLogin = ({ credential }: CredentialResponse) => {
        setGoogleLoginData(credential);
        authenticateWithGoogle.onRefresh({
            credential,
        });
    };

    useEffect(() => {
        if (loginReq?.response?.message === "admin is inactive") {
            setOpenInactiveAdminModal(true);
        }
    }, [loginReq.response]);

    useEffect(() => {
        if (authenticateWithGoogle?.response?.message === "admin is inactive") {
            setOpenInactiveAdminModal(true);
        }
    }, [authenticateWithGoogle.response]);

    /*    useEffect(() => {
        if (credential) {
            authenticateWithGoogle.onRefresh({
                credential,
                terms: true,
                howYouKnowUs,
            });
        }
    }, [credential]); */

    useEffect(() => {
        if (
            authenticateWithGoogle.response &&
            !userMustFinishRegister &&
            !authenticateWithGoogle.response.message
        ) {
            if (authenticateWithGoogle.response?.firstLogin) {
                Mixpanel.track("Criou com Google", {
                    email: authenticateWithGoogle.response?.user?.email,
                });
            }
            login(authenticateWithGoogle.response);
        }
    }, [authenticateWithGoogle.response]);

    useEffect(() => {
        if (
            getUser.response &&
            authenticateWithGoogle.response &&
            !userMustFinishRegister
        ) {
            handleUserOrganizationData(getUser, authenticateWithGoogle);
        }
    }, [authenticateWithGoogle.response]);

    useEffect(() => {
        if (
            getProjects.response &&
            authenticateWithGoogle.response &&
            !userMustFinishRegister
        ) {
            setProjects(
                getProjects.response,
                authenticateWithGoogle.response.user
            );
        }
    }, [getProjects.response]);

    useEffect(() => {
        if (userMustFinishRegister && authenticateWithGoogle.response) {
            navigate(
                `/cadastro?credential=${authenticateWithGoogle.response.credential}&google=true&name=${authenticateWithGoogle.response.name}`
            );
        }
    }, [authenticateWithGoogle.response]);

    useEffect(() => {
        if (loginReq.error) {
            setError("password", { message: "E-mail ou senha incorretos" });
            setError("email", { message: " " });
        }
        if (loginReq.response && !loginReq.response.message) {
            login(loginReq.response);
        }
        if (loginReq.response && openInactiveAdminModal) {
            toast.success("Conta reativada com sucesso!");
            setOpenInactiveAdminModal(false);
        }
    }, [loginReq.response || loginReq.error]);

    useEffect(() => {
        if (getUser.response && loginReq.response) {
            handleUserOrganizationData(getUser, loginReq);
        }
    }, [getUser.response]);

    useEffect(() => {
        if (getProjects.response && loginReq.response) {
            setProjects(getProjects.response, loginReq.response.user);
        }
    }, [getProjects.response]);

    useEffect(() => {
        if (reason === "sem-permissao")
            toast.error(
                "Suas permissões foram atualizadas. Logue-se novamente!"
            );
        if (reason === "erro")
            toast.error("Erro! Entre em contato com o suporte.");
        if (reason === "inactivation")
            toast.success(
                "Conta inativada com sucesso! Caso queira ativar sua conta, basta logar na plataforma",
                {
                    duration: 5000,
                }
            );
    }, [reason]);

    const handleActiveAccount = () => {
        if (googleLoginData) {
            authenticateWithGoogle.onRefresh({
                credential: googleLoginData,
                reactivate: true,
            });
            return;
        }
        if (!loginData) return;
        loginReq.onRefresh({
            ...loginData,
            reactivate: true,
        });
    };

    return (
        <s.Main>
            {openInactiveAdminModal && (
                <InactiveAdminModal
                    onOpenChange={setOpenInactiveAdminModal}
                    onClickSuccess={handleActiveAccount}
                    onClickCancel={() => {
                        setOpenInactiveAdminModal(false);
                        setLoginData(undefined);
                        setGoogleLoginData(undefined);
                    }}
                    loading={loginReq.loading || authenticateWithGoogle.loading}
                />
            )}
            <s.Section>
                <s.Img alt={"foto não carregada"} src={FotoMoca} />
            </s.Section>
            <s.SectionColumn>
                <s.Container>
                    <s.H1 style={{ marginBottom: "44px" }}>
                        Que bom ter você por aqui!
                    </s.H1>

                    <s.Google>
                        <GoogleLogin
                            onSuccess={handleGoogleLogin}
                            onError={() => {
                                toast.error(
                                    "Erro! Tente novamente mais tarde ou faça o login com e-mail e senha."
                                );
                            }}
                        />
                        <p>ou</p>
                    </s.Google>

                    <s.Form onSubmit={handleSubmit(onSubmit)}>
                        {generateForm().map((input, index) => (
                            <Input
                                key={index}
                                {...register(input.id, {
                                    onBlur: () => trigger(input.id),
                                })}
                                type={input.type}
                                placeholder={input.placeholder}
                                error={errors[input.id]?.message}
                                label={input.label}
                                icon={input.icon}
                                mask=""
                            />
                        ))}
                        <PrimaryButton loading={loginReq.loading}>
                            Entrar
                        </PrimaryButton>
                    </s.Form>
                    <s.FlexColumnCenter
                        style={{ marginTop: "16px" }}
                        gap={"24px"}
                    >
                        <Link to="/recuperar-senha">
                            <s.A>Esqueceu sua senha?</s.A>
                        </Link>
                        <s.FlexRowCenter gap={"8px"}>
                            <s.P>Ainda não tem conta?</s.P>
                            <Link to="/cadastro">
                                <s.A>Cadastre-se aqui</s.A>
                            </Link>
                        </s.FlexRowCenter>
                    </s.FlexColumnCenter>
                </s.Container>
            </s.SectionColumn>
        </s.Main>
    );
};
