import { useQuery } from "@tanstack/react-query";

import { projects } from "../../services";
import {
    ReadProjectCostsParams,
    ReadProjectCostsResult,
} from "../../services/projects/types";

type Params = {
    enabled?: boolean;
    cacheTime?: number;
};

export function useReadCosts({
    enabled = true,
    endDate,
    orderByDate,
    projectId,
    startDate,
    cacheTime,
}: ReadProjectCostsParams & Params) {
    return useQuery<ReadProjectCostsResult, unknown, ReadProjectCostsResult>({
        queryKey: ["readCosts"],
        queryFn: () =>
            projects.readCosts({ endDate, orderByDate, projectId, startDate }),
        enabled,
        cacheTime,
    });
}
