import { useQuery } from "@tanstack/react-query";

import { projects } from "../../services";

type Params = {
    page: number;
    length: number;
    searchTerm?: string;
    filterType?: "late" | "onTime" | "finished" | "noInformation" | "all";
    archived?: boolean;
};

export function useReadDemonstratives({
    page = 1,
    length = 6,
    searchTerm,
    filterType,
    archived,
}: Params) {
    return useQuery({
        queryKey: ["readDemonstratives"],
        queryFn: () =>
            projects.readDemonstratives({
                page,
                length,
                searchTerm,
                filterType,
                archived,
            }),
    });
}
