import { axiosHttpClient } from "../axios";
import handleStatusCases from "../handle-status-cases";

const { REACT_APP_BASE_URL } = process.env;

export default async (inviteToken?: string) => {
    const httpResponse = await axiosHttpClient({
        url: `${REACT_APP_BASE_URL}/invite/redeem`,
        method: "get",
        headers: { "x-invite-token": inviteToken },
    });
    const { body, statusCode } = httpResponse;

    const errorFeedBacks = {
        400: "Convite inválido",
        409: "Usuário já existente/convite já existe para este usuário!",
        500: "Email não verificado",
    };

    return handleStatusCases({
        body,
        statusCode,
        errorFeedBacks,
    });
};
